exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-reviews-api-js": () => import("./../../../src/pages/online-reviews-api.js" /* webpackChunkName: "component---src-pages-online-reviews-api-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-scrapers-js": () => import("./../../../src/pages/scrapers.js" /* webpackChunkName: "component---src-pages-scrapers-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-scrape-platform-template-js": () => import("./../../../src/templates/scrapePlatformTemplate.js" /* webpackChunkName: "component---src-templates-scrape-platform-template-js" */),
  "component---src-templates-scraper-page-template-js": () => import("./../../../src/templates/scraperPageTemplate.js" /* webpackChunkName: "component---src-templates-scraper-page-template-js" */)
}

